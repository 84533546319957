import { NgModule } from '@angular/core';
import { SocialAuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';

import { GoogleSignInComponent } from './google-sign-in.component';
import { environment } from '@environments/environment';

@NgModule({
  declarations: [GoogleSignInComponent],
  exports: [GoogleSignInComponent],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
})
export class GoogleSignInModule {}
