import { Component, ViewEncapsulation } from '@angular/core';
import { SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';

@Component({
  selector: 'app-google-sign-in',
  templateUrl: './google-sign-in.component.html',
  styleUrls: ['./google-sign-in.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GoogleSignInComponent {
  constructor(private socialAuthService: SocialAuthService) {}

  loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
}
