export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  NON_BINARY = 'Non-Binary',
  TRANSGENDER = 'Transgender',
}

export enum ApplicationStatus {
  PRE_SCREENING_INCOMPLETE = 'prescreening-incomplete',
  SCREENING_INCOMPLETE = 'screening-incomplete',
  DECLINED = 'decline',
  APPROVED = 'approve',
  PENDING = 'pending',
  CLIENT = 'client',
}

export enum QuestionType {
  STRING = 'string',
  SELECT = 'select',
}

export const YesOrNoAnswers = [
  {
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'No',
    label: 'No',
  },
];

export enum Ethnicity {
  WHITE = 'white',
  ASIAN = 'asian',
  HISPANIC_OR_LATINO = 'hispanic_or_latino',
  BLACK_OR_AFRICAN_AMERICAN = 'black_or_african_american',
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'american_indian_or_alaska_native',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'native_hawaiian_or_other_pacific_islander',
}
