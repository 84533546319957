import { Directive, ElementRef, Input, Inject, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[text-area]'
})
export class TextAreaDirective implements OnChanges {
  // Private
  private _nativeElement: any;

  @Input('text-area') text!: string;

  /**
   * Constructor
   *
   * @param {ElementRef} _elementRef
   */
  constructor(
    @Inject(ElementRef) private _elementRef: ElementRef,
    @Inject(ChangeDetectorRef) private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this._nativeElement = this._elementRef.nativeElement;

    this.text = changes.text ? changes.text.currentValue : '';

    this._nativeElement.outerHTML = this.text.replaceAll('\n', '<br />');
    this._changeDetector.markForCheck();
  }
}
