import { Applicant } from './applicant';

export enum ProgramStatus {
  COMPLETED = 'completed',
  ABANDONED = 'abandoned',
  STARTED_PROGRAM = 'started_program',
  YET_TO_STARTED_PROGRAM = 'yet_to_started_program',
}

export interface Client extends Applicant {
  approvedAt: string;
  programStartedAt: string;
  programStatus: ProgramStatus;
  programSinceStartedAt: string;
}
