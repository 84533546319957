import { Pipe, PipeTransform } from '@angular/core';
import differenceInDays from 'date-fns/differenceInDays';

@Pipe({
  name: 'differenceInDays'
})
export class DifferenceInDays implements PipeTransform {
  transform(dateLeft: number, dateRight: string): any {
    return differenceInDays(new Date(dateLeft), new Date(dateRight));
  }
}
