import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'programStatus',
})
export class ProgramStatusPipe implements PipeTransform {
  transform(status: string): string {
    return {
      completed: 'Completed',
      abandoned: 'Abandoned',
      yet_to_started_program: 'Yet To Start Program',
      started_program: 'Started Program',
    }[status];
  }
}
