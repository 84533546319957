import { Pipe, PipeTransform } from '@angular/core';
import { Ethnicity } from '@types';

@Pipe({
  name: 'ethnicity',
})
export class EthnicityPipe implements PipeTransform {
  transform(status: string): string {
    return {
      [Ethnicity.WHITE]: 'White',
      [Ethnicity.ASIAN]: 'Asian',
      [Ethnicity.HISPANIC_OR_LATINO]: 'Hispanic or Latino',
      [Ethnicity.BLACK_OR_AFRICAN_AMERICAN]: 'Black or African American',
      [Ethnicity.AMERICAN_INDIAN_OR_ALASKA_NATIVE]: 'American Indian or Alaska Native',
      [Ethnicity.NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER]: 'Native Hawaiian or Other Pacific Islander',
    }[status];
  }
}
